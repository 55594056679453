<template>
  <v-card flat>
    <v-overlay
      v-if="loading"
      absolute
      opacity="0.1"
    >
      <card-loader />
    </v-overlay>
    <template v-if="subscription && !subscription.pending_update && hasUnpaidInvoices">
      <v-card-text>
        <v-alert type="warning" outlined>
          You have unpaid invoices. <router-link :to="{ name: 'billing' }" v-text="'Click here'" /> to go to your invoice history and pay those off in order to make changes to your subscription.
        </v-alert>
      </v-card-text>
    </template>
    <validation-observer v-else slim>
      <v-card-text v-if="!start">
        You may add or remove plans from your subscription below. Upgrading or adding plans to your subscription will result in an immediate invoice for the remaining duration of your new subscription. Removing or downgrading will issue a credit to your account, which you can use towards your future payments.
      </v-card-text>
      <v-card outlined>
        <v-data-table
          :items="tableItems"
          :headers="tableHeaders"
          hide-default-footer
          no-data-text="No plans available"
        >
          <template #[`item.name`]="{ item }">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name | displayPlan }}
                </v-list-item-title>
                <!-- <v-list-item-subtitle v-if="requiredPlans[item.name]">
                  You need {{ requiredPlans[item.name] }} plans.
                </v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </template>
          <template #[`item.quantity`]="{ item }">
            <v-text-field
              v-model="cart[item.name]"
              outlined
              dense
              type="number"
              hide-details
              class="quantity-input"
              append-outer-icon="mdi-plus"
              @click:append-outer="cart[item.name]++"
              @blur="validateQuantity(item.name)"
            >
              <template #prepend>
                <v-icon
                  :style="{ opacity: cart[item.name] > 0 ? '1' : '0', cursor: cart[item.name] > 0 ? 'pointer' : 'default' }"
                  @click="cart[item.name] > 0 ? cart[item.name]-- : null"
                >
                  mdi-minus
                </v-icon>
              </template>
            </v-text-field>
          </template>
          <template #[`item.current`]="{ item }">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{ current[item.name] }} x {{ prices[plans[item.name][currentInterval].id] | formatPrice }}<span class="caption">/{{ currentInterval }}</span></v-list-item-subtitle>
                <v-list-item-title>{{ (current[item.name] * prices[plans[item.name][currentInterval].id]) | formatPrice }}<span class="caption">/{{ currentInterval }}</span></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template #[`item.cart`]="{ item }">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{ cart[item.name] || 0 }} x {{ prices[plans[item.name][interval].id] | formatPrice }}<span class="caption">/{{ interval }}</span></v-list-item-subtitle>
                <v-list-item-title>{{ (cart[item.name] * prices[plans[item.name][interval].id]) | formatPrice }}<span class="caption">/{{ interval }}</span></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-data-table>
      </v-card>
      <v-card-text>
        <v-chip-group
          v-model="interval"
          mandatory
          center-active
          color="primary darken-1"
          class="justify-center"
        >
          <v-chip
            value="month"
            x-large
            filter
            label
          >
            <span class="px-3">
              Billed Monhtly
            </span>
          </v-chip>
          <v-chip
            value="year"
            x-large
            filter
            label
          >
            <span class="px-3">
              Billed Yearly
            </span>
          </v-chip>
        </v-chip-group>
      </v-card-text>
      <v-row>
        <v-col
          v-if="!start && currentTotal >= 0"
          cols="12"
          md="6"
        >
          <v-card outlined>
            <v-card-text>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Current Subscription:
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="flex-row justify-end align-center">
                  <span class="title">{{ currentTotal | formatPrice }}<span class="caption">/{{ currentInterval }}</span></span>
                  <span v-if="customer && customer.address.country === 'DE'">+ VAT</span>
                </v-list-item-action>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-if="newTotal"
          cols="12"
          md="6"
          :offset-md="start ? 6 : 0"
        >
          <v-card outlined>
            <v-card-text>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-if="start">
                    Subscription Total:
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    New Subscription:
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="flex-row justify-end align-center">
                  <span class="title">{{ newTotal | formatPrice }}<span class="caption">/{{ interval }}</span></span>
                  <span v-if="customer.address.country === 'DE'">+ VAT</span>
                </v-list-item-action>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!start && upcomingInvoice && changed && !(currentTotal === 0 && newTotal === 0)" justify="end">
        <v-col>
          <v-card outlined>
            <v-card-text>
              <v-list>
                <v-subheader>
                  Payable Now
                </v-subheader>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ upcomingInvoice.tax > 0 ? 'Subtotal' : 'Total' }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Total difference of your subscription {{ upcomingInvoice.subtotal > 0 ? 'upgrade' : 'downgrade' }} for the current billing period.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="title">
                    {{ upcomingInvoice.subtotal | formatPrice }}
                  </v-list-item-action>
                </v-list-item>
                <template v-if="upcomingInvoice.tax > 0">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        Tax
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        19% VAT is applied for customers in Germany.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="title">
                      {{ upcomingInvoice.tax | formatPrice }}
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        Total
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="title">
                      {{ upcomingInvoice.total | formatPrice }}
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <v-list-item v-if="upcomingInvoice.starting_balance < 0" two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      Applied Balance
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Your remaining balance after this payment will be {{ upcomingInvoice.ending_balance | formatPrice }}.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="title">
                    {{ (upcomingInvoice.starting_balance - upcomingInvoice.ending_balance) | formatPrice }}
                  </v-list-item-action>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      Payable Now
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="upcomingInvoice.amount_due > 0">
                      This is the amount that will be charged following your confirmation.
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      You will not be charged for this {{ upcomingInvoice.total > 0 ? 'upgrade' : 'downgrade' }}.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="title">
                    {{ upcomingInvoice.amount_due | formatPrice }}
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                large
                color="primary"
                :loading="updating"
                @click="createOrUpdateSubscription"
              >
                <template v-if="upcomingInvoice.total > 0">
                  <template v-if="upcomingInvoice.amount_due > 0">
                    Confirm upgrade and pay now
                  </template>
                  <template v-else>
                    Confirm upgrade without costs
                  </template>
                </template>
                <template v-else>
                  Confirm downgrade
                </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </validation-observer>
    <v-overlay v-if="updating">
      <card-loader message="Please hold on..." />
    </v-overlay>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'Subscription',
  props: {
    start: {
      type: Boolean,
      default: () => false
    },
    customer: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    loading: true,
    updating: false,
    interval: 'month',
    currentInterval: 'month',
    headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'name',
        sortable: false
      },
      {
        text: 'Current Plan',
        align: 'right',
        value: 'current',
        sortable: false
      },
      {
        text: 'Quantity',
        align: 'right',
        value: 'quantity',
        sortable: false
      },
      {
        text: 'New Plan',
        align: 'right',
        value: 'cart',
        sortable: false
      }
    ],
    plans: {},
    subItemIds: {},
    prices: {},
    current: {
      advertising: 0,
      view: 0,
      touch: 0
    },
    cart: {
      advertising: 0,
      view: 0,
      touch: 0
    },
    upcomingInvoice: null
  }),
  computed: {
    ...mapState(['subscription']),
    ...mapGetters(['organization', 'requiredPlans']),
    tableHeaders() {
      if (!this.start) {
        return this.headers
      }
      return this.headers.filter(x => x.value !== 'current')
    },
    tableItems() {
      if (this.loading) {
        return []
      }
      const items = []
      for (const plan of Object.keys(this.plans)) {
        const { month, year } = this.plans[plan]
        items.push({
          name: plan,
          amount: {
            month: this.prices[month.id],
            year: this.prices[year.id]
          }
        })
      }
      items.sort((a, b) => {
        return a.amount.month - b.amount.month
      })
      return items
    },
    changed() {
      return JSON.stringify(this.cart) !== JSON.stringify(this.current) || this.interval !== this.currentInterval
    },
    currentTotal() {
      let total = 0
      for (const plan of Object.keys(this.plans)) {
        const planId = this.plans[plan][this.currentInterval].id
        total += this.current[plan] * this.prices[planId]
      }
      return total
    },
    newTotal() {
      if (!this.changed) {
        return null
      }
      let total = 0
      for (const plan of Object.keys(this.plans)) {
        const planId = this.plans[plan][this.interval].id
        total += this.cart[plan] * this.prices[planId]
      }
      return total
    },
    items() {
      return Object.keys(this.cart).map((plan) => {
        return {
          id: this.subItemIds[plan],
          plan: this.plans[plan][this.interval].id,
          quantity: this.cart[plan]
        }
      })
    },
    hasUnpaidInvoices() {
      if (!this.customer || !this.customer.invoices.length) {
        return false
      }
      return !!this.customer.invoices.find(x => !x.paid)
    }
  },
  watch: {
    cart: {
      handler() {
        this.previewChanges()
      },
      deep: true
    },
    interval() {
      this.previewChanges()
    },
    subscription: {
      handler() {
        this.initialize()
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {
    await this.initialize()
    this.loading = false
  },
  methods: {
    async initialize() {
      if (!Object.keys(this.plans).length) {
        await this.fetchPlans()
      }
      this.upcomingInvoice = null
      this.current = {
        advertising: 0,
        view: 0,
        touch: 0
      }
      this.cart = {
        advertising: 0,
        view: 0,
        touch: 0
      }
      const subscription = this.subscription
      if (subscription && subscription.items && subscription.items.data) {
        const currentIntervals = subscription.items.data.map(x => x.plan.interval)
        const currentInterval = currentIntervals[0]
        if (!currentIntervals.every(x => x === currentInterval)) {
          alert('interval mismatch error')
          return
        }
        this.currentInterval = currentInterval
        this.interval = currentInterval
        for (const subItem of subscription.items.data) {
          const planName = subItem.plan.nickname.split(' ')[0].toLowerCase()
          const quantity = subItem.quantity || 0
          this.$set(this.subItemIds, planName, subItem.id)
          this.$set(this.current, planName, quantity)
          this.$set(this.cart, planName, quantity)
        }
        if (subscription.pending_update) {
          const pendingItems = subscription.pending_update.subscription_items
          const pendingIntervals = pendingItems.map(x => x.plan.interval)
          const pendingInterval = pendingIntervals[0]
          if (!pendingIntervals.every(x => x === pendingInterval)) {
            // eslint-disable-next-line
            console.error('pending interval mismatch error')
            return
          }
          this.interval = pendingInterval
          for (const subItem of pendingItems) {
            const planName = subItem.plan.nickname.split(' ')[0].toLowerCase()
            const quantity = subItem.quantity || 0
            this.$set(this.cart, planName, quantity)
          }
        }
      }
    },
    validateQuantity(plan) {
      const quantity = parseInt(this.cart[plan])
      if (!quantity || quantity < 0) {
        this.$set(this.cart, plan, this.current[plan])
      }
    },
    async fetchPlans() {
      try {
        const { data } = await this.$axios.get(`${this.$apiUrl}/stripe/plans/`)
        for (const plan of data) {
          const { basePlan, visibility } = plan.metadata
          if (visibility === 'all') {
            if (!(basePlan in this.plans)) {
              this.$set(this.plans, basePlan, {})
            }
            this.$set(this.plans[basePlan], plan.interval, plan)
            this.$set(this.prices, plan.id, plan.amount)
          }
        }
      } catch (error) {
        this.error(error)
      }
    },
    async previewChanges(force) {
      if (!this.changed && !force) {
        this.upcomingInvoice = null
        return
      }
      this.start && this.$emit('items', this.items)
      const { stripeCustomerId } = this.organization
      // if there is no customer, this will be the first plan
      if (!stripeCustomerId) {
        let subtotal = 0
        for (const basePlan of Object.keys(this.plans)) {
          subtotal += this.plans[basePlan][this.interval].amount * this.cart[basePlan]
        }

        let tax = 0
        let taxPercent = 0
        let total = subtotal
        if (this.customer.address.country === 'DE') {
          taxPercent = 19
          tax = parseInt(subtotal * (taxPercent / 100))
          total = subtotal + tax
        }
        this.upcomingInvoice = {
          subtotal,
          tax_percent: taxPercent,
          tax,
          total,
          amount_due: total
        }
        this.start && this.$emit('upcoming', this.upcomingInvoice)
        return
      }
      this.loading = true
      const form = {
        stripeCustomerId,
        items: this.items
      }
      if (this.subscription && this.subscription.id) {
        form.subscriptionId = this.subscription.id
      }
      const { data } = await this.$axios.post(`${this.$apiUrl}/stripe/retrieve-upcoming/`, form)
      this.upcomingInvoice = data
      this.start && this.$emit('upcoming', this.upcomingInvoice)
      this.loading = false
    },
    async createOrUpdateSubscription() {
      this.updating = true
      const { stripeCustomerId } = this.organization
      const subscriptionId = (this.subscription || {}).id
      try {
        const { data, error } = await this.$axios.post(`${this.$apiUrl}/stripe/subscription/`, {
          stripeCustomerId,
          subscriptionId,
          items: this.items
        })
        if (error) {
          return this.error(error)
        }
        const paymentIntent = data.latest_invoice.payment_intent
        if (!paymentIntent) {
          return this.success()
        }
        if (paymentIntent.status !== 'requires_action') {
          return this.success()
        }
        const response = await this.$stripe.confirmCardPayment(paymentIntent.client_secret)
        if (!response.error && response.paymentIntent.status === 'succeeded') {
          return this.success()
        }
        // Display error message in your UI.
        // The card was declined (i.e. insufficient funds, card has expired, etc)
        this.error(response.error.message)
      } catch (error) {
        this.error(error)
      }
    },
    error(error) {
      this.$store.dispatch('newSnackbar', { type: 'error', message: error })
      this.$emit('refresh')
      this.updating = false
    },
    success() {
      this.$store.dispatch('newSnackbar', { type: 'success', message: 'Your payment was successfully processed.' })
      this.$emit('refresh')
      this.updating = false
    }
  }
}
</script>

<style lang="sass">
@import 'vuetify/src/styles/settings/_variables.scss'
.quantity-input
  align-items: center
  justify-content: flex-end
  .v-input__control
    max-width: 70px
    @media #{map-get($display-breakpoints, 'xs-only')}
      margin: 1rem 0
    input
      text-align: right
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none
  margin: 0 /* <-- Apparently some margin are still there even though it's hidden */
.interval-input.v-input--radio-group--column .v-input--radio-group__input
  flex-direction: row
  align-items: center
  .v-radio:not(:last-child):not(:only-child)
    margin-bottom: 0
.v-chip-group.justify-center .v-slide-group__content
  justify-content: center
</style>
